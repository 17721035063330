import abpLogo1 from "../Assets/abp-logo-1.png";
import brand1 from "../Assets/blue-ribbon.png";
import brand8 from "../Assets/company_logo.png";
import brand9 from "../Assets/company_logo2.png";
import brand3 from "../Assets/i2ifunding.png";
import brand6 from "../Assets/logo_ditto_insurance.png";
import brand7 from "../Assets/logo_noora_health.png";
import brand2 from "../Assets/razorpay.png";

export const brands = [
  brand6,
  abpLogo1,
  brand1,
  // abpLogo2,
  // abpLogo3,
  // abpLogo4,
  // abpLogo5,
];
