import { Box, Flex, Heading, Stack, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import assetsDemoVideo from "../../../Assets/video/assets-demo.mp4";
import Container from "../../../Components/Container/Container";
import { PrimaryButton } from "../../../Components/PrimaryButton";
import VerticalTextSlider from "../../../Components/VerticalTextSlider/VerticalTextSlider";

const sliderText = [
  "Paid Leaves",
  "Assets",
  "Mutual Funds",
  "Vehicle",
  "Stocks",
  "Insurance",
];

export const Hero = () => {
  return (
    <Container>
      <Box
        // height={"100vh"}
        width={"full"}
        overflowX={"hidden"}
        py={{ base: 5, md: 10, lg: 20 }}
      >
        <Box>
          <Stack
            direction={{ base: "column", md: "row" }}
            justifyContent={"center"}
            alignItems={{ base: "left", md: "center" }}
            spacing={{ base: 0, md: 14, lg: 14, "2xl": "20" }}
            gap={{ base: 10, md: 14, lg: 20 }}
            // h={"100vh"}
          >
            <Box
              w={{
                base: "full",
                md: "500px",
                lg: "500px",
                "2xl": "600px",
              }}
            >
              <Heading
                fontSize={{
                  base: "4xl",
                  md: "5xl",
                  lg: "5xl",
                  "2xl": "6xl",
                }}
                fontWeight={"bold"}
                noOfLines={4}
              >
                <Flex gap={3}>
                  <Text>Use</Text>
                  <VerticalTextSlider sliderText={sliderText} />
                </Flex>
                as coupon code{" "}
                <Text
                  display={{
                    base: "inherit",
                    md: "inline",
                  }}
                >
                  for loans
                </Text>
              </Heading>
              <Text
                fontWeight={"bold"}
                fontSize={{ base: "lg", lg: "xl", "2xl": "2xl" }}
                color="#656"
                marginBottom={{ base: "25px", md: "25px", lg: "35px" }}
              >
                Easy, fast & Secure
              </Text>

              <Link to={"https://app.keeper.co.in/"} target="_blank">
                {" "}
                <PrimaryButton
                // customStyle={{ w: { base: "full", md: "initial" } }}
                >
                  Check Eligibility Now
                </PrimaryButton>
              </Link>
            </Box>
            <Flex alignSelf={"center"}>
              <Box
                w={{
                  base: "200px",
                  md: "200px",
                  lg: "240px",
                }}
                marginRight={{ base: 0, md: 0, lg: 20, "2xl": 20 }}
                marginY={{ base: "30px", md: 0 }}
              >
                <video
                  width="100%"
                  height="100%"
                  autoPlay="autoplay"
                  muted
                  loop
                  disableRemotePlayback
                >
                  <source src={assetsDemoVideo} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </Box>
            </Flex>
          </Stack>
        </Box>
      </Box>
    </Container>
  );
};
