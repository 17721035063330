import { Box } from "@chakra-ui/layout";

export default function PrivacyPolicy() {
  return (
    <>
      <Box p={5}>
        <b>Privacy Policy</b>
        <br />
        <p>
          We, at OnionKnights Software Private Limited (&ldquo;Keeper&rdquo; or
          &ldquo;We&rdquo;) understand Privacy and its value. Therefore, it is
          all the more important for us to make YOU (&ldquo;You&rdquo; or
          &ldquo;Customer&rdquo; or &ldquo;User&rdquo;), the User of the website
          www.keeper.co.in (the &ldquo;Website&rdquo;) and its associated mobile
          applications, Keeper (&ldquo;Application&rdquo;) (collectively, the
          &ldquo;Platform&rdquo;) understand the reason behind collection of
          your information and its usage and the manner in which we collect,
          use, store and share information about you (&ldquo;Privacy
          Policy&rdquo;). This Privacy Policy has been prepared in compliance
          with:
        </p>

        <p>
          Information Technology (Reasonable Security Practices and Procedures
          and Sensitive Personal Data or Information) Rules, 2011;
        </p>

        <p>
          Information Technology (Intermediary Guidelines and Digital Media
          Ethics Code) Rules, 2021
        </p>

        <p>
          Guidelines on Digital Lending issued by the Reserve Bank of India
          (RBI), 2022;
        </p>

        <p>
          Other applicable acts, regulations and rules which requires the
          publishing of a privacy policy for handling of or dealing in personal
          information including sensitive personal data or information and all
          applicable laws, regulations, guidelines provided by applicable
          regulatory authorities including but not limited to the RBI.
        </p>

        <p>
          This Privacy Policy is incorporated into and at all times is subject
          to and is to be read in conjunction with the Terms of Use of the
          Platform.
        </p>

        <p>CONSENT</p>

        <p>
          You hereby expressly consent to provide the information that may be
          required in relation to the Services (as defined below) being rendered
          on the Platform by us. You acknowledge that we shall collect the
          information detailed under this Privacy Policy to facilitate lending
          &amp; non-lending services by partnering with various financial
          lenders, third parties, service providers, etc based on your
          requirement to avail such Services (&ldquo;Services&rdquo;).
        </p>

        <p>
          Keeper will only be using the information for providing the Services
          to you.
        </p>

        <p>
          In order to avail any Services being provided by Keeper by itself or
          in partnership with the lenders or other third parties it is important
          that YOU READ, UNDERSTAND, ACKNOWLEDGE AND UNCONDITIONALLY AGREE TO BE
          BOUND BY THE TERMS AND CONDITIONS OF THIS PRIVACY POLICY.
        </p>

        <p>
          IF YOU DO NOT AGREE TO THIS POLICY OR ANY PART THEREOF, PLEASE DO NOT
          USE/ ACCESS/ DOWNLOAD/ INSTALL THE PLATFORM OR ANY PART THEREOF.
        </p>

        <p>
          For the users consenting to continue accessing the Platform and avail
          the Services, this Privacy Policy explains our policies and practices
          regarding the collection, use, and disclosure of Your information.
        </p>

        <p>COLLECTION OF INFORMATION</p>

        <p>
          The collection of information under this Privacy Policy are conducted
          for the following categories of services:
        </p>

        <p>
          (i) Part A: Information for Digital Lending Services: Information
          collected by Platform for facilitation of Loans being disbursed by the
          financial lending partners whose details are available on the Website
          and the Application and who are registered with the Reserve Bank of
          India (&ldquo;Lending Partners&rdquo;).
        </p>

        <p>
          (ii) Part B: Information for Non-Lending Services: Information
          collected by Platform while registering a User on the Platform or
          while providing Value Added Services (i.e. all services other than
          facilitation of loans).
        </p>

        <p>
          A. Information we collect about you In order to facilitate the lending
          and non-lending services, Keeper will be required to access, collect
          and share Personal Information with its lending partners that may be
          banks or NBFCs registered with the Reserve Bank of India or any other
          third-party providing value added services in partnership with Keeper.
          In such cases, Keeper will share the information securely and ensure
          that all personal information recipients comply with confidentiality,
          fidelity and secrecy obligations and sign covenants in this regard.
          Keeper may make information available to third parties that are
          financial and non-financial companies, government agencies, courts,
          legal investigators, and other non-affiliated third parties as
          requested by You or Your authorized representative, or otherwise when
          required or permitted by law.
        </p>

        <p>
          User Personal Information: The data points we collect from You for
          both lending and non-lending services include, inter alia, your full
          name, email id, PAN, GST Network user id &amp; password, address,
          mobile number, postal code.
        </p>

        <p>
          Social Account Information: Keeper may provide you with the option to
          register using social accounts (Google or HRMS) to access the app and
          shall collect only such registered email id and user public profile
          information like name, email, ASID depending on the platform used by
          You to log-into the Application during registration/ sign in process
          in the Platform.
        </p>

        <p>
          How we use this information: We may collect and store email id, name
          and address associated with that account for the purpose of
          verification and to pre-populate relevant fields in the course of
          Platform interface. However, we shall not collect / store account
          passwords.
        </p>

        <p>
          SMS Information: Keeper collects, stores and monitors SMS information
          from your device. However, we do not store or monitor your Personal
          SMS information.
        </p>

        <p>
          How we use this information: We use this data to provide you with
          updates or confirmation of any actions taken in our Platform during
          the term of Services. We shall collect any SMS information for
          facilitating you any lending services and non-lending service
          including such purposes as may be required by the Lending Partners or
          as per applicable law. This category of information is only collected
          for providing the non-lending services or our value-added services
        </p>

        <p>
          Device Information and Installed Apps data: We additionally collect
          certain device information provided herein for our lending and
          non-lending services. Information which the Application collects, and
          its usage, depends on how you manage your privacy controls on your
          device.
        </p>

        <p>
          (i) Device Information: When you install the Application, we store the
          information we collect with unique identifiers tied to the device you
          are using. We collect information from the device when you download
          and install the Application and explicitly seek permissions from You
          to get the required information from the device. Additionally, we also
          collect your Log information (via the domain server through which the
          User accesses the App Search queries, IP address, crashes, date etc
          for the purpose of improvising the Application functionality. In
          addition to the above, we also track and collect the data related to
          the performance of the Application and other diagnostic data for
          identifying and resolving any technical glitches that may be
          identified from such data and also for improving the overall
          functionality of the Application.
        </p>

        <p>
          How we use the information: We collect information about your device
          to provide automatic updates and additional security so that your
          account is not used in other people&rsquo;s devices. In addition, the
          information provides us valuable feedback on your identity as a device
          holder as well as your device behaviour, thereby allowing us to
          improve our products interaction, quality of services and provide an
          enhanced customized user experience to you. We further collect other
          identifiable information such as your transactions history on the
          Platform when you set up a free account with us. (ii) Installed
          Application Data: We collect and transmit a list of specific installed
          applications&rsquo; metadata information which includes the
          application name, package name, installed time, updated time, version
          name and version code of each installed application on your device.
          This data may be collected even when the app is closed or not in use.
          How we use this information: We use this information for your
          onboarding and Know Your Customer (KYC) purpose with your explicit
          consent.
        </p>

        <p>
          Location, Camera, Microphone Access: We will collect your device
          location and request camera and microphone access for facilitating
          lending services as well as providing any non-lending services only in
          accordance with applicable laws. How we use this information: We shall
          collect your device location information for verifying your address,
          for the KYC and onboarding process for the Services. We may request
          camera access to scan and capture the required KYC documents as per
          the instructions of our Lending Partners in accordance with applicable
          laws. We shall transfer such information to our Lending Partners and
          shall not store any such data. We require microphone permissions to
          enable a two-way communication between our authorised agents and you
          for the purpose of performing and completing your Video KYC for the
          Lending Services. Your audio shall be recorded for regulatory purpose.
        </p>

        <p>B. Information about you we collect from third parties</p>

        <p>
          For making the Services available to you, we may collect credit
          information by obtaining specific authorisations from you (if required
          under applicable laws), from certain third parties such as credit
          bureaus or credit rating companies as your &lsquo;authorised
          representative&rsquo; from time to time in accordance with applicable
          laws during the loan journey as may be requested by our Lending
          Partners.
        </p>

        <p>
          In order to facilitate credit products to you, we may receive certain
          information pertaining to document verification, repayment status etc
          from certain third parties including NSDL, payment gateway providers.
        </p>

        <p>
          We may further collect your GST details from Official GSTIN API stack
          or other relevant websites using the GST Network user id and password
          details or OTP as provided by you.
        </p>

        <p>
          We may further collect your bank account numbers or UPI payment
          information on behalf of our lending partners to facilitate collection
          and repayment of loan payments.
        </p>

        <p>
          We shall only collect this information on a need basis strictly for
          the purpose of providing you with the Services. The information
          collected from such third parties are not retained by us. We collect
          this information as part of our outsourcing obligations to our Lending
          Partners and is directly transferred to the Lending Partners upon
          collection.
        </p>

        <p>C. Information you give us about you</p>

        <p>
          In due course of using our Services, you are required to submit data
          to enable our Services. We use this data to create your profile and
          provide you with the best available services. Mentioned below is some
          of the data we collect from you:
        </p>

        <p>
          i. Data provided by you by filling in forms in the Application or on
          the Website. ii. Data provided by corresponding with us (for example,
          by e-mail or chat). iii. Data and information, you provide when you
          register to use the Website, download or register on our App,
          subscribe to any of our Services (such as applying for a loan), search
          for a Service, and when you report a problem with our App, our
          Services, or any of our Sites. iv. Data including your name, address,
          gender, date of birth, e-mail address, phone number, username,
          password and other registration information. v. PAN Card, Aadhaar
          Card, financial information such as employer name, monthly salary,
          bank account no., bank statements, credit information, GST
          information, copies of identification documents which are forwarded to
          our Lending Partners for the onboarding of your application to avail
          the services. vi. Data generated by your usage of our Platform. vii.
          Contact details (i.e. the phone number)of the third party individuals
          (including your relatives and friends) that are voluntarily added by
          you at the time of loan application.
        </p>

        <p>
          This data helps us create your profiles, complete mandatory KYC as per
          the requirements of our Lending Partners who offer you the Services,
          unlock and approve loans and provide you with customized support in
          case of issues. Please note that we do not store any data provided by
          you except for the basic information such as name, address, your
          contact details etc.
        </p>

        <p>
          Wherever possible, we indicate the mandatory and the optional fields.
          You always have the option to not provide any information by choosing
          not to use a particular service or feature on the Platform. While you
          can browse some sections of our Platform without being a registered
          member as mentioned above, certain activities (such as availing of
          loans from the third-party lenders on the Platform) require
          registration and for you to provide the above details.
        </p>

        <p>
          We under no circumstances and at no point take any biometric data from
          you for any of our services or operations. In case, if any of our
          representatives ask for the same from you, we request you to kindly
          refrain from doing the same and address this concern to our Grievance
          Officer (the details of the same have been provided below).
        </p>

        <p>D. Storage of Personal Information</p>

        <p>
          For the provision of lending Services, we shall only store basic
          personal information such as your name, your address, your contact
          information which are required to be stored for carrying out our
          non-lending services. We ensure that every data or information that we
          collect from you is stored in servers located in India and the same is
          compliance with all the statutory/regulatory obligations. For other
          personal information collected as part of our outsourcing services
          being provided to our Lending Partners, we shall collect such
          information upon the instructions of the Lending Partners and
          thereafter transfer the same to the Lending Partners upon the
          completion of the preliminary onboarding.
        </p>

        <p>E. Collection of Certain Non-Personal Information</p>

        <p>
          We automatically track certain information about you based upon your
          behaviour on our Platform. We use this information to do internal
          research on our users&rsquo; demographics, interests, and behaviour to
          better understand, protect and serve our users and improve our
          services. This information is compiled and analysed on an aggregated
          basis.
        </p>

        <p>
          Cookies: Cookies are small data files that a Website stores on Your
          computer. We will use cookies on our Website similar to other lending
          websites / apps and online marketplace websites / apps. Use of this
          information helps Us identify You in order to make our Website more
          user friendly. Most browsers will permit You to decline cookies but if
          You choose to do this it might affect service on some parts of Our
          Website.
        </p>

        <p>
          If you choose to make a purchase through the Platform, we collect
          information about your buying behaviour. We retain this information as
          necessary to resolve disputes, provide customer support and
          troubleshoot problems as permitted by law. If you send us personal
          correspondence, such as emails or letters, or if other users or third
          parties send us correspondence about your activities or postings on
          the Website, we collect such information into a file specific to you.
        </p>

        <p>PURPOSE OF COLLECTION</p>

        <p>
          We shall use the information collected from you for facilitating the
          lending and non-lending Services for the following purposes as
          detailed below. We understand the importance of your information and
          ensure that it is used for the following intended purposes only.
        </p>

        <p>
          The intended purpose of collecting information provided by you is to:
        </p>

        <p>establish identity and verify the same;</p>

        <p>
          to facilitate your KYC as per instructions from our Lending Partners;
        </p>

        <p>
          troubleshoot problems, monitor, improve and administer our Platform;
        </p>

        <p>
          provide our service i.e. facilitating loans to You or providing our
          value-added services or non-lending services to you.
        </p>

        <p>
          design and offer customized products and services offered by our
          third-party partners;
        </p>

        <p>
          analyse how the Platform is used, diagnose service or technical
          problems and maintain security;
        </p>

        <p>
          send communications notifications, information regarding the products
          or services requested by You or process queries and applications that
          You have made on the Platform;
        </p>

        <p>
          measure consumer interest and satisfaction in our products and
          services and manage Our relationship with You;
        </p>

        <p>
          marketing and promotional purposes including sending you promotional
          SMS, Email and WhatsApp and inform you about online and offline
          offers, products, services, and updates;
        </p>

        <p>
          conduct data analysis in order to improve the Services / Products
          provided to the User;
        </p>

        <p>
          use the User information in order to comply with country laws and
          regulations;
        </p>

        <p>
          use the User information in other ways permitted by law to enable You
          to take financial services from our lending partners.
        </p>

        <p>
          resolve disputes and detect and protect us against suspicious or
          illegal activity, fraud and other criminal activity;
        </p>

        <p>customize your experience and enforce our terms and conditions.</p>

        <p>
          the contact details (i.e. the phone number) of the third party
          individuals (including your relatives and friends) that are
          voluntarily added by you at the time of loan application may be used
          for the purpose of establishing contact in case we are unable to
          contact you, including at the time of collection of installments.
        </p>

        <p>
          We will use and retain only such basic personal information such as
          your name, contact information, address details and such other
          information which are necessary for the provision of Services and for
          such periods as necessary to provide You the Services on the Platform,
          to comply with our legal obligations, to resolve disputes, and enforce
          our agreements.
        </p>

        <p>DISCLOSURE TO THIRD PARTIES</p>

        <p>
          We will share Your information only with our third parties including
          our regulated financial partners, vendors etc for facilitation of
          Services on the Platform.
        </p>

        <p>
          We will share the information only in such manner as described below:
        </p>

        <p>
          We disclose and share Your information with the financial service
          providers, banks or NBFCs and our Lending Partners for facilitation of
          a loan or facility or line of credit or purchase of a product;
        </p>

        <p>
          We share Your information with our third-party partners in order to
          conduct data analysis in order to serve You better and provide
          Services our Platform;
        </p>

        <p>
          We may disclose Your information, in order to enforce or apply our
          terms of use or assign such information in the course of corporate
          divestitures, mergers, or to protect the rights, property, or safety
          of us, our users, or others. This includes exchanging information with
          other companies and organizations for the purposes of fraud protection
          and credit risk reduction.
        </p>

        <p>
          We will disclose the data / information provided by a user with other
          technology partners to track how the user interact with the Platform
          on our behalf.
        </p>

        <p>
          We and our affiliates may share Your information with another business
          entity should we (or our assets) merge with, or be acquired by that
          business entity, or re-organization, amalgamation, restructuring of
          business for continuity of business. Should such a transaction occur
          than any business entity (or the new combined entity) receiving any
          such information from us shall be bound by this Policy with respect to
          your information.
        </p>

        <p>
          We will disclose the information to our third-party technology or
          third-party data source providers;
        </p>

        <p>
          We will share Your information under a confidentiality agreement with
          the third parties and restrict use of the said information by third
          parties only for the purposes detailed herein. We warrant that there
          will be no unauthorised disclosure of your information shared with
          third parties.
        </p>

        <p>
          We shall disclose your KYC journey or any data with respect to the
          same to the relevant regulatory authorities as a part of our statutory
          audit process. Please note that your Aadhaar number shall never be
          disclosed.
        </p>

        <p>
          We may share your personal information with the governmental
          authorities, quasi-governmental authorities, judicial authorities and
          quasi-judicial authorities if we are acting under any duty, request or
          order as part of our legal obligations and in accordance to the
          applicable laws. By accepting this Privacy Policy, you hereby provide
          your consent to disclose your personal information for such regulatory
          disclosure.
        </p>

        <p>Any disclosure to third parties is subject to the following:</p>

        <p>
          If we are under a duty to disclose or share your personal data in
          order to comply with any legal or regulatory obligation or request, we
          shall not seek your explicit consent however we shall reasonably
          endeavour to notify the same to you accordingly as the case may be as
          stated under subclause 3;
        </p>

        <p>
          We shall take your express consent in the event we share your personal
          data with third parties;
        </p>

        <p>
          We shall share your information with third-party only on a need basis
          and only for the purpose stated hereunder, as per the applicable laws.
        </p>

        <p>
          We shall additionally seek express consent through a separate consent
          for at appropriate stages of data collection, if so required under
          applicable laws.
        </p>

        <p>
          Usage of your information by such third parties is subject to their
          privacy policies. We share limited information with them, strictly to
          the extent required. We recommend you to have a look at the privacy
          policies of such third parties.
        </p>

        <p>
          List of entities with whom your information is shared can be assessed
          at https://www.Keeper.in/our-lending-partners
        </p>

        <p>DATA RETENTION AND DELETION</p>

        <p>
          We at Keeper promise to protect your personal data from unauthorized
          access, misuse, and disclosure using the right security measures based
          on the type of data and how we are processing the same. We retain
          information about you to provide a seamless experience, to contact you
          in case of support required and about your account, to detect,
          mitigate, prevent, and investigate fraudulent or illegal activities
          during the course of the Services. We retain your data for as long as
          necessary to provide you with our services. We may also retain and use
          your basic personal information inter alia as name, contact number,
          transactional details and address details as necessary to comply with
          our legal obligations, resolve disputes, and enforce our agreements
          which shall always be in accordance with applicable laws. Subject to
          this section, we may delete your data upon reasonable written request
          for the same at any stage. However, you may not be able to use our
          services after deletion.
        </p>

        <p>LINK TO THIRD-PARTY SDK AND OTHER SITES</p>

        <p>
          The App has a link to registered third party SDKs, Application
          Programming Interface (API) integrations, redirections which collects
          data on our behalf and data is stored to a secured server. We ensure
          that our third-party service provider takes extensive security
          measures in order to protect your personal information against loss,
          misuse or alteration of the data as required under the applicable
          laws.
        </p>

        <p>
          Our third-party service provider employs separation of environments
          and segregation of duties and have strict role-based access control on
          a documented, authorized, need-to-use basis. The stored data is
          protected and stored by application-level encryption. They enforce key
          management services to limit access to data.
        </p>

        <p>
          Furthermore, our registered third-party service provider provides
          hosting security &ndash; they use industry-leading anti-virus,
          anti-malware, intrusion prevention systems, intrusion detection
          systems, file integrity monitoring, and application control solutions.
        </p>

        <p>
          We don't allow unauthorized access to your non-public personal
          contacts or financial transaction SMS data with any third party in
          relation to Lending Services other than our Lending Partners. However,
          for Non-Lending Services (Value Added Services i. e. Expense Tracker),
          we may allow third parties to access your financial transaction SMS
          subject to your explicit consent.
        </p>

        <p>
          Our Platform links to other websites that may collect information
          about you. We are not responsible for the privacy practices or the
          content of those linked websites. With this Policy we&rsquo;re only
          addressing the disclosure and use of data collected by Us. Their data
          collection practices, and their policies might be different from this
          Policy and We do not have control over any of their policies neither
          do we have any liability in this regard.
        </p>

        <p>CHANGES IN THIS PRIVACY POLICY</p>

        <p>
          We reserve the right to change, modify, add, or remove portions of
          this Privacy Policy at any time for any reason. In case, any changes
          are made in the Privacy Policy, we shall update the same on the
          Platform. Once posted, those changes are effective immediately, unless
          stated otherwise. We encourage you to periodically review this page
          for the latest information on our privacy practices. Continued access
          or use of the Services constitute Your acceptance of the changes and
          the amended Privacy Policy.
        </p>

        <p>SECURITY PRECAUTIONS</p>

        <p>
          The Platform intends to protect your information and to maintain its
          accuracy as confirmed by you. We implement reasonable physical,
          administrative and technical safeguards to help us protect your
          information from unauthorized access, use and disclosure. For example,
          we encrypt all information when we transmit over the internet. We also
          require that our registered third-party service providers protect such
          information from unauthorized access, use and disclosure.
        </p>

        <p>
          Our Platform has stringent security measures in place to protect the
          loss, misuse and alteration of information under control. We endeavour
          to safeguard and ensure the security of the information provided by
          you. We use Secure Sockets Layers (SSL) based encryption, for the
          transmission of the information, which is currently the required level
          of encryption in India as per applicable law.
        </p>

        <p>
          We blend security at multiple steps within our products with the
          state-of-the-art technology to ensure our systems maintain strong
          security measures and the overall data and privacy security design
          allow us to defend our systems ranging from low hanging issue up to
          sophisticated attacks.
        </p>

        <p>
          In addition, the Website and App have been certified for the following
          security certifications:
        </p>

        <p>
          ISO 9001: being the international standard that details requirements
          for a quality management system (QMS). Organizations use the standard
          to demonstrate the ability to consistently provide products and
          services that meet customer and regulatory requirements with the
          requisite security protections.
        </p>

        <p>
          ISO 27001 (formally known as ISO/IEC 27001:2005): is a specification
          for an information security management system (ISMS) and is the
          suggested level of certification required under the Information
          Technology Act, 2000. An ISMS is a framework of policies and
          procedures that includes all legal, physical and technical controls
          involved in an organization&rsquo;s information risk management
          processes.
        </p>

        <p>
          We aim to protect from unauthorized access, alteration, disclosure or
          destruction of information we hold, including:
        </p>

        <p>We use encryption to keep your data private while in transit;</p>

        <p>
          We offer security feature like an OTP verification to help you protect
          your account;
        </p>

        <p>
          We review our information collection, storage, and processing
          practices, including physical security measures, to prevent
          unauthorized access to our systems;
        </p>

        <p>
          We restrict access to personal information to our employees,
          contractors, and agents who need that information in order to process
          it. Anyone with this access is subject to strict contractual
          confidentiality obligations and may be disciplined or terminated if
          they fail to meet these obligations;
        </p>

        <p>
          Compliance &amp; Cooperation with Regulations and applicable laws;
        </p>

        <p>
          We regularly review this Privacy Policy and make sure that we process
          your information in ways that comply with it.
        </p>

        <p>Data transfers;</p>

        <p>We ensure that Aadhaar number is not disclosed in any manner.</p>

        <p>
          Data protection laws vary among countries, with some providing more
          protection than others. We also comply with certain legal frameworks
          relating to the transfer of data as mentioned and required under the
          Information Technology Act, 2000, rules and the amendments made
          thereunder.
        </p>

        <p>
          When we receive formal written complaints, we respond by contacting
          the person who made the complaint. We work with the appropriate
          regulatory authorities, including local data protection authorities,
          to resolve any complaints regarding the transfer of your data that we
          cannot resolve with you directly.
        </p>

        <p>YOUR RIGHTS</p>

        <p>
          Modifying or rectifying your information: In the event that any
          Personal Information provided by You is inaccurate, incomplete or
          outdated then You shall have the right to provide Us with the
          accurate, complete and up to date data and have Us rectify such data
          at Our end immediately. We urge You to ensure that You always provide
          Us with accurate and correct information/data to ensure Your use of
          Our Services is uninterrupted. In case of modification of Personal
          Information, Users will be required to furnish supporting documents
          relating to change in Personal Information for the purpose of
          verification by the Company.
        </p>

        <p>
          Your Privacy Controls: You have certain choices regarding the
          information we collect and how it is used:
        </p>

        <p>
          Your device may have controls that determine what information we
          collect. For example, you can modify permissions on your Android/iOS
          device or Browser to remove any permissions that may have been given.
          However, Platform does not provide a guarantee of Services if any such
          controls are exercised.
        </p>

        <p>Delete the App from your device</p>

        <p>
          You can also request to remove content from our servers in accordance
          with Clause C.
        </p>

        <p>
          Withdrawal/Denial of consent: You acknowledge that the Platform has
          duly collected the information with your consent and you have the
          option to not provide such information, or deny consent for use of
          specific information or revoke the consent already given. However, any
          withdrawal of such personal information will not be permitted in case
          any Service availed by You is active. Where a consent has been
          withdrawn the Platform does not guarantee or be liable for providing
          such Service. You shall have the following rights pertaining to your
          information collected by us.
        </p>

        <p>
          Deny Consent: You shall have the right to deny consent for use of
          specific data, restrict disclosure to third parties, data retention,
          revoke consent already granted to collect personal data and if
          required, make the App delete/ forget the data. However, any such
          denial will not prejudice the right of the Lending Partners to retain
          any data in relation to the loans availed by you or by the non-lending
          service providers in relation to the non-lending services provided to
          you. Further, in case of a denial of a consent, the Platform does not
          provide a guarantee or will not be liable towards the continued
          facilitation of the Services if any such controls are exercised.
        </p>

        <p>
          Withdraw Consent: You may withdraw Your consent to contact You, for
          the continued collection, use or disclosure of Your information, at
          any time, or request for deletion of your Login account by raising a
          request on the Platform or by mailing Us at hello@keeper.co.in.
          However, Platform does not provide a guarantee of Services if any such
          controls are exercised. Further, if You have availed any loan
          facilities from Our lending partner, the lending partner shall have
          the right to continue processing Your information till such credit
          facility has been repaid in full, along with any interest and dues
          payable and/or for such period as may be allowed under applicable law.
          However, We, shall not retain Your data and information if it is no
          longer required by Us and there is no legal requirement to retain the
          same. Do note that multiple legal bases may exist in parallel, and We
          may still have to retain certain data and information at any time.
          Also, the information may still be used for execution of any
          outstanding or termination activity of any Lending or Non-lending
          Services.
        </p>

        <p>
          Report an issue: You have a right to report a security incident to the
          GRO (detailed mentioned hereinbelow). You are entitled shall be
          entitled to prevent unauthorised such usage of your information by our
          personnel/agents by informing us, within 10 days of being informed of
          the proposed use, that you do not wish to disclose such information.
          You can also exercise the right at any time by contacting us at
          hello@keeper.co.in
        </p>

        <p>
          Notwithstanding anything to the contrary stated above, the following
          are specific scenarios listed below which may have consequences to
          your withdrawal of consent:
        </p>

        <p>
          Marketing and Communication: The consent for this information be
          withdrawn if You write an email to the email address provided under
          clause VIII.
        </p>

        <p>
          Information/data deletion may not be implemented for ongoing Services
          including loan, insurance policy or digital gold products.
        </p>

        <p>GRIEVANCE REDRESSAL</p>

        <p>
          You may make a request for deleting any information from the Platform
          at any stage upon making a request to Us in the following manner:
        </p>

        <p>Grievance Officer</p>

        <p>
          In accordance with Information Technology Act 2000 and rules made
          there under, the name and contact details of the Grievance Officer are
          provided below for your reference:
        </p>

        <p>Name: Mr. Sushant Mehta</p>

        <p>Address: OnionKnights Software Private Limited,</p>

        <p>MyWorx, Sector2 Noida - 201301</p>

        <p>Email: hello@keeper.co.in</p>

        <p>Time: Mon - Sat (10:00 - 19:00)</p>
      </Box>
    </>
  );
}
