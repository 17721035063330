import { Box, Grid, GridItem, Image, Text } from "@chakra-ui/react";
import React from "react";
import { brands } from "../../logos/brandLogos";
import Container from "../Container/Container";
import { CustomSectionHeader } from "../CustomSectionHeader";

export const Brands = () => {
  return (
    <Box
      bg={"#F9F9F9"}
      pb={{ base: 0, md: 5 }}
      pt={6}
      my={{ base: 4, md: 6, lg: 8 }}
    >
      <Container>
        <Box>
          <CustomSectionHeader
            borderDisplay={"none"}
            headingStyle={{ fontSize: "2xl" }}
          >
            Trusted by
          </CustomSectionHeader>
          <Box overflow={"hidden"} py={{ base: 6, md: 8 }}>
            <Grid
              templateColumns={{
                base: "repeat(2, 1fr)",
                sm: "repeat(2, 1fr)",
                md: "repeat(3, 1fr)",
                lg: "repeat(4, 1fr)",
              }}
              placeItems={"center"}
              mx={"auto"}
              gap={{ base: 10, md: 16 }}
            >
              {brands?.map((item, i) => (
                <GridItem key={i}>
                  <Box w={"150px"} h={"100px"}>
                    <Image
                      src={item}
                      w={"full"}
                      h={"full"}
                      objectFit={"contain"}
                      filter={"grayscale(1)"}
                    />
                  </Box>
                </GridItem>
              ))}
              <GridItem alignSelf={"end"}>
                <Box>
                  <Text
                    fontSize={"xl"}
                    fontWeight={"semibold"}
                    color={"#333333"}
                  >
                    And many more...
                  </Text>
                </Box>
              </GridItem>
            </Grid>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
