import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  Icon,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { CustomSectionHeader } from "../../Components/CustomSectionHeader";
import { forEmployees, forEmployer } from "./Data/faqData";

export default function FAQ() {
  return (
    <>
      <Box py={{ base: 4, md: 6, lg: 8 }} my={{ base: 4, md: 6, lg: 8 }}>
        <Box>
          <Text textAlign={"center"} mb={2} fontWeight={500}>
            HAVE ANY DOUBTS
          </Text>
          <CustomSectionHeader>Frequently asked Questions</CustomSectionHeader>
        </Box>
        <Box mx="auto" mt={10}>
          <Tabs position="relative" variant="unstyled">
            <TabList
              justifyContent={"space-between"}
              bg={"rgba(236, 236, 255, 0.41)"}
              py={2}
            >
              <Tab
                w={"full"}
                fontWeight={"bold"}
                fontSize={{ base: "lg", md: "xl" }}
                color={"primary"}
                outline={"none"}
                style={{ WebkitTapHighlightColor: "transparent" }}
              >
                For Employer
              </Tab>
              <Divider
                orientation="vertical"
                w={"5px"}
                h={"25px"}
                borderLeftWidth={2}
                borderColor={"gray.200"}
                my={"auto"}
              />
              <Tab
                w={"full"}
                fontWeight={"bold"}
                fontSize={{ base: "lg", md: "xl" }}
                color={"primary"}
                outline={"none"}
                style={{ WebkitTapHighlightColor: "transparent" }}
              >
                For Employees
              </Tab>
            </TabList>
            <TabIndicator
              mt="-16"
              height="3px"
              bg="primary"
              borderRadius="1px"
            />
            <TabPanels w={{ base: "90%", md: "70%" }} mx={"auto"}>
              {[forEmployer, forEmployees]?.map((faqs, i) => (
                <TabPanel
                  key={i}
                  outline={"none"}
                  style={{ WebkitTapHighlightColor: "transparent" }}
                >
                  <Box maxW={"1280px"}>
                    <Accordion allowToggle defaultIndex={[0]}>
                      {faqs?.map((v, i) => (
                        <AccordionItem
                          key={i}
                          borderColor={"white"}
                          my={2}
                          bg={"#F3F3F3"}
                          borderRadius={"10px"}
                        >
                          {({ isExpanded }) => (
                            <>
                              <h2>
                                <AccordionButton
                                  style={{
                                    backgroundColor: "transparent",
                                    border: "1px solid #F3F3F3",
                                    color: "#000",
                                    borderRadius: "10px",
                                    padding: "15px",
                                  }}
                                  _expanded={{
                                    borderRadius: "10px 10px 0 0 !important",
                                    borderBottomWidth: "0 !important",
                                    paddingBottom: "5px !important",
                                  }}
                                >
                                  <Box
                                    as="span"
                                    flex="1"
                                    textAlign="left"
                                    fontSize={{
                                      base: "lg",
                                      md: "xl",
                                      lg: "xl",
                                      "2xl": "2xl",
                                    }}
                                  >
                                    {v?.question}
                                  </Box>
                                  {isExpanded ? (
                                    <Icon as={BiChevronUp} h={6} w={6} />
                                  ) : (
                                    <Icon as={BiChevronDown} h={6} w={6} />
                                  )}
                                </AccordionButton>
                              </h2>
                              <AccordionPanel
                                pb={4}
                                style={{
                                  borderRadius: "0 0 10px 10px",
                                }}
                                color={"gray.600"}
                                fontSize={{
                                  base: "md",
                                  md: "lg",
                                  lg: "lg",
                                  "2xl": "xl",
                                }}
                              >
                                {v?.answer}
                              </AccordionPanel>
                            </>
                          )}
                        </AccordionItem>
                      ))}
                    </Accordion>
                  </Box>
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </Box>
      </Box>
    </>
  );
}
