import { Flex, Heading, Image, Stack, Text } from "@chakra-ui/react";

export const ContentCard = ({ topic }) => {
  const { title, description, thumbnail } = topic;
  return (
    <Flex
      flexDir={{ base: "column-reverse", md: "row", lg: "row" }}
      _even={{
        flexDir: { base: "column-reverse", md: "row" },
      }}
      _odd={{
        flexDir: { base: "column-reverse", md: "row-reverse" },
      }}
      justify={"space-between"}
      gap={{ base: 10, md: 20 }}
      align={"center"}
      p={{ base: 5, md: 0 }}
    >
      <Stack
        spacing={4}
        mb={{ base: 0, md: 5 }}
        bg={"white"}
        height={"fit-content"}
        w={{ base: "full", md: "45%" }}
      >
        <Heading
          fontSize={"2xl"}
          fontWeight="bold"
          mt={{
            base: 2,
            md: 2,
          }}
          color={"gray.600"}
          sx={{
            fontSize: { base: "2xl", md: "3xl", lg: "3xl", "2xl": "4xl" },
            mt: "0 !important",
          }}
        >
          {title}
        </Heading>
        <Text
          color={"gray.800"}
          fontSize={{ base: "md", lg: "lg", "2xl": "xl" }}
        >
          {description}
        </Text>
      </Stack>

      <Flex w={{ base: "full", md: "35%" }} justify={"center"}>
        <Image
          justifySelf={"center"}
          alt={"feature image"}
          w={"full"}
          h={"full"}
          src={thumbnail}
          objectFit={"cover"}
        />
      </Flex>
    </Flex>
  );
};
