import { Box, Flex, Text, VStack, VisuallyHidden } from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import img3 from "../../Assets/life-insurance.png";
import img2 from "../../Assets/mutual-funds.png";
import img1 from "../../Assets/paid-leaves.png";
import formatNumberWithCommas from "../../utilities/formatNumberWithCommas";
import AssetsCard from "../AssetsCard/AssetsCard";
import Container from "../Container/Container";
import { CustomSectionHeader } from "../CustomSectionHeader";
import RangeSlider from "../RangeSlider/RangeSlider";

const assets = [
  {
    id: 1,
    title: "Paid Leaves",
    subtitle: "30 Leaves",
    image: img1,
    interest: 14,
    loanValue: 53378,
  },
  {
    id: 2,
    title: "Mutual Funds",
    subtitle: "Take My Breath ( Offici..",
    image: img2,
    interest: 12,
    loanValue: 29378,
  },
  {
    id: 3,
    title: "Life Insurance",
    subtitle: "30 Leaves",
    image: img3,
    interest: 11.5,
    loanValue: 23378,
  },
];

const amounts = {
  requiredAmount: {
    title: "Required Amount:",
    amount: "₹ 1,00,000",
  },
  unsecuredAmount: {
    title: "Unsecured Amount:",
    amount: "₹ 1,00,000",
  },
  securedAmount: {
    title: "Secured Amount:",
    amount: "₹ 0",
  },
};

const EMPLOYEE_REQUESTED_AMOUNT = 100000;
export default function ChooseAssets() {
  const [selectedAssets, setSelectedAssets] = useState([]);
  const [calculatedInterest, setCalculatedInterest] = useState(0);
  const [assetsRef, assetsInView] = useInView({
    triggerOnce: true, // Animation triggers only once
  });

  const getTotalInterestAndAssets = useCallback(() => {
    let availableAssets = [];
    let remainingAmount = Number(EMPLOYEE_REQUESTED_AMOUNT);
    let toTake = true;
    let totalInterst = 0;
    let data = selectedAssets;

    // set value in variable
    const setAssetAndInterestValue = (item) => {
      availableAssets.push(item);
      totalInterst =
        totalInterst +
        (item.takenVal / EMPLOYEE_REQUESTED_AMOUNT) * item.interest;
    };

    data.forEach((item) => {
      if (toTake) {
        if (remainingAmount > 0 && item.loanValue < remainingAmount) {
          remainingAmount = remainingAmount - item.loanValue;
          item.takenVal = item.loanValue;
          setAssetAndInterestValue(item);
        } else if (remainingAmount > 0 && item.loanValue > remainingAmount) {
          item.takenVal = remainingAmount;
          remainingAmount = 0;
          toTake = false;
          setAssetAndInterestValue(item);
        }
      }
    });

    if (remainingAmount > 0) {
      let temp = {};
      temp.id = -1;
      temp.loanValue = -1;
      temp.interest = 20;
      temp.takenVal = remainingAmount;
      setAssetAndInterestValue(temp);
    }

    const removeDuplicateAssets = availableAssets.filter(
      (asset, i) => availableAssets.indexOf(asset) === i
    );

    setCalculatedInterest(totalInterst);
    setUnsecuredAmount(removeDuplicateAssets);
    setSecuredAmount(removeDuplicateAssets);
  }, [selectedAssets]);

  // set unsecured amount
  const setUnsecuredAmount = (loanAssets) => {
    const amount = loanAssets?.find((item) => item.id === -1);
    amounts.unsecuredAmount.amount = `₹ ${
      amount ? formatNumberWithCommas(amount?.takenVal) : 0
    }`;
  };

  // set secured Amount
  const setSecuredAmount = (loanAssets) => {
    const totalAmount = loanAssets?.reduce((acc, cur) => {
      if (cur?.id === -1) {
        return acc;
      }
      return acc + cur?.takenVal;
    }, 0);

    amounts.securedAmount.amount = `₹ ${
      totalAmount > 0 ? formatNumberWithCommas(totalAmount) : 0
    }`;
  };

  useEffect(() => {
    getTotalInterestAndAssets();
  }, [getTotalInterestAndAssets, selectedAssets]);

  return (
    <>
      <Box py={{ base: 4, md: 6, lg: 8 }} my={{ base: 4, md: 6, lg: 8 }}>
        <Container>
          <CustomSectionHeader>Choose from multiple assets</CustomSectionHeader>
          <Flex
            flexDir={{ base: "column", md: "row", lg: "row" }}
            justify={"center"}
            gap={{ base: "14", md: "28", lg: "36" }}
            align={"center"}
            mt={{ base: 12, md: 14, lg: 20 }}
          >
            <VStack
              alignItems={"baseline"}
              gap={5}
              w={{ base: "full", md: "initial" }}
            >
              {assets?.map((asset, i) => (
                <AssetsCard
                  key={i}
                  itemsLength={assets?.length}
                  asset={asset}
                  selectedAssets={selectedAssets}
                  setSelectedAssets={setSelectedAssets}
                  assetsInView={assetsInView}
                />
              ))}
            </VStack>
            <Box w={{ base: "full", lg: "400px" }}>
              <VStack>
                {Object.keys(amounts)?.map((key, i) => (
                  <Flex
                    key={i}
                    w={"full"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    color="#858585"
                    pt={i === 3 ? "32px" : 0}
                  >
                    <Text
                      fontSize={{ base: "lg", md: "xl", lg: "xl" }}
                      fontWeight={"semibold"}
                    >
                      {amounts[key]?.title}
                    </Text>
                    <Box w={{ base: "85px", md: "150px" }}>
                      <Text textAlign={"left"}>{amounts[key]?.amount}</Text>
                    </Box>
                  </Flex>
                ))}
                <Flex
                  w={"full"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  color="#858585"
                  pt={"32px"}
                >
                  <Text
                    fontSize={{ base: "lg", md: "xl", lg: "xl" }}
                    fontWeight={"semibold"}
                  >
                    Interest Rate
                  </Text>
                  <Box w={{ base: "85px", md: "150px" }}>
                    <Text textAlign={"left"}>
                      {Number.isSafeInteger(calculatedInterest)
                        ? calculatedInterest
                        : calculatedInterest?.toFixed(2)}
                      % p.a.
                    </Text>
                  </Box>
                </Flex>
              </VStack>

              <RangeSlider value={calculatedInterest} max={20} />
            </Box>
          </Flex>
          <VisuallyHidden ref={assetsRef}>
            Hidden div for tracking component entry
          </VisuallyHidden>
        </Container>
      </Box>
    </>
  );
}
