import { Box } from "@chakra-ui/react";
import React from "react";

const calculatePercentage = (value, max) => {
  const parseValue = parseFloat(value.toFixed(1));
  const percentage = (parseValue / max) * 100;
  return parseInt(percentage);
};
export default function RangeSlider({ max, value }) {
  return (
    <>
      <Box
        pos={"relative"}
        w={{ base: "full", md: "calc(100% - 70px)" }}
        h={1}
        mt={8}
      >
        <Box
          w={"full"}
          h={"full"}
          bg={"rgba(48, 127, 226, 0.30)"}
          rounded={"full"}
        ></Box>
        <Box
          w={`${calculatePercentage(value, max)}%`}
          h={"full"}
          bg={"#6765E8"}
          rounded={"full"}
          pos={"absolute"}
          top={0}
          left={0}
          transitionDuration={"1s"}
        ></Box>
        <Box
          w={"14px"}
          h={"14px"}
          bg={"#6765E8"}
          rounded={"full"}
          pos={"absolute"}
          top={"50%"}
          left={`calc(${calculatePercentage(value, max)}% - 9px)`}
          transitionDuration={"1s"}
          style={{ transform: "translateY(-50%)" }}
        ></Box>
      </Box>
    </>
  );
}
