import { Box, SimpleGrid } from "@chakra-ui/react";
import img3 from "../../Assets/interest_rate.png";
import img1 from "../../Assets/loan_anytime.png";
import img2 from "../../Assets/zero_percent.png";
import Container from "../Container/Container";
import { ContentCard } from "../ContentCard/ContentCard";
import { CustomSectionHeader } from "../CustomSectionHeader";

const topics = [
  {
    title: "Easiest access to Credit",
    description:
      "We offer easy salary advance against your encashable leaves to help you achieve your financial goals",
    thumbnail: img1,
  },
  {
    title: "Zero late fee",
    description:
      "With Keeper leave-backed salary advance you can rest easy knowing that you won't be penalized for late payments or early repayments. We believe in providing transperent and flexible credit options that work for you, without any hidden fees or charges.",
    thumbnail: img2,
  },
  {
    title: "Get lowest-cost credit",
    description:
      "Keeper offers the cheapest salary advance as it is backed by your encashable leaves.",
    thumbnail: img3,
  },
];

export const SmartBorrowingSection = () => {
  return (
    <>
      <Container>
        <Box
          py={{ base: 4, md: 6, lg: 8 }}
          my={{ base: 4, md: 6, lg: 8 }}
          id={"more-info"}
        >
          <CustomSectionHeader borderDisplay={"none"}>
            Keeper: A smarter way to borrow, repay
          </CustomSectionHeader>
          <SimpleGrid
            columns={{ base: 1, md: 1 }}
            mt={{ base: 5, md: 10, lg: 16 }}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            {topics?.map((topic, i) => (
              <ContentCard key={i} topic={topic} />
            ))}
          </SimpleGrid>
        </Box>
      </Container>
    </>
  );
};
