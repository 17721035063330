import { Button } from "@chakra-ui/react";
import React from "react";

export const PrimaryButton = ({ children, customStyle }) => {
  return (
    <>
      <Button
        borderRadius={"full"}
        fontWeight={600}
        color={"white"}
        bg={"primary"}
        _hover={{
          bg: "primary",
        }}
        paddingX={"50px"}
        paddingY={{ base: "20px", lg: "25px" }}
        boxShadow={
          "0px 3px 3px 0px rgba(0, 0, 0, 0.15), 0px 3px 3px 0px rgba(0, 0, 0, 0.15)"
        }
        sx={customStyle}
        outline={"none"}
        style={{ WebkitTapHighlightColor: "transparent" }}
      >
        {children}
      </Button>
    </>
  );
};
