import { Brands } from "../../Components/Brands/Brands";
import ChooseAssets from "../../Components/ChooseAssets/ChooseAssets";
import { SmartBorrowingSection } from "../../Components/SmartBorrowingSection/SmartBorrowingSection";
import useDynamicTitle from "../../Hooks/useDynamicTitle";
import FAQ from "../FAQ/FAQ";
import { Hero } from "./Hero/Hero";

export default function Home() {
  useDynamicTitle("Turn unused leaves into cash!");
  return (
    <>
      <Hero />
      <Brands />
      <SmartBorrowingSection />
      <ChooseAssets />
      {/* <Testimonials /> */}
      <FAQ />
    </>
  );
}
