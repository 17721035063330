export const getSortedValue = (array, condition) => {
  const copyArray = [...array];
  return copyArray.sort((a, b) => {
    if (condition === "inc") {
      return a.interest - b.interest;
    } else {
      return b.interest - a.interest;
    }
  });
};
