import { Box, HStack, Image, SimpleGrid, Stack, Text } from "@chakra-ui/react";
import React from "react";
import startupIndiaLogo from "../../Assets/Startup-India-transparent.png";
import logoWhite from "../../Assets/logo_white.png";
import Container from "../Container/Container";
import { CustomContainer } from "../CustomContainer";
import { FooterLinks } from "./FooterLinks/FooterLinks";

export const Footer = () => {
  return (
    <>
      <Box bg={"#010049"}>
        <Container>
          <Box
            pt={10}
            pb={{ base: 24, md: 10 }}
            color={"gray.50"}
            // px={{ base: 8, md: 16 }}
          >
            <CustomContainer>
              <SimpleGrid
                templateColumns={{
                  sm: "1fr",
                  md: "2fr 1fr 1fr",
                  lg: "2fr 1fr 1fr 1fr 1fr",
                }}
                spacing={8}
              >
                <Stack
                  spacing={{ sm: 3, md: 5 }}
                  alignItems="start"
                  justify="start"
                  direction={{
                    base: "column",
                    sm: "column",
                    md: "row",
                    lg: "row",
                    xl: "row",
                    "2xl": "row",
                  }}
                >
                  <HStack spacing={2}>
                    <Image src={logoWhite} w={"45px"} h={"45px"} />
                    <Text fontWeight={"semibold"} fontSize={"2xl"}>
                      Keeper
                    </Text>
                  </HStack>
                  <Box w="150px">
                    <Image
                      src={startupIndiaLogo}
                      w={"full"}
                      h={"full"}
                      objectFit="contain"
                      mt="17px"
                    />
                  </Box>
                </Stack>
                <FooterLinks />
              </SimpleGrid>
            </CustomContainer>
          </Box>
        </Container>
      </Box>
      {/* <WaitlistSubscription /> */}
    </>
  );
};
