import { Box } from "@chakra-ui/react";
import React from "react";

export default function Container({ children }) {
  return (
    <>
      <Box maxW={"1280px"} margin={"auto"} px={5}>
        {children}
      </Box>
    </>
  );
}
