import {
  Box,
  Flex,
  HStack,
  Heading,
  IconButton,
  Image,
  Text,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { BsCheckLg } from "react-icons/bs";
import { HiPlus } from "react-icons/hi";
import formatNumberWithCommas from "../../utilities/formatNumberWithCommas";
import { getSortedValue } from "../../utilities/getSortedValue";

export default function AssetsCard({
  asset,
  selectedAssets,
  setSelectedAssets,
  assetsInView,
  itemsLength,
}) {
  const { id, title, image, interest, loanValue } = asset || {};
  const [animationTrigeer, setAnimationTriger] = useState(true);

  const getSelectedAssets = (id) => {
    let assets;
    const available = selectedAssets.some((item) => item.id === id);
    if (available) {
      const restAssets = selectedAssets.filter((item) => item.id !== id);
      assets = restAssets;
    } else {
      assets = [...selectedAssets, { id, interest, loanValue }];
    }

    setSelectedAssets(getSortedValue(assets, "inc"));
  };

  const checkedAsset = () => {
    return selectedAssets.some((item) => item.id === id);
  };

  // card animation functionality
  const createCardAnimation = useCallback(
    (item, addCard, removeCard, delay, isAnimate) => {
      setTimeout(() => {
        if (removeCard?.isRemove) {
          setSelectedAssets((prev) =>
            prev.filter((item) => item?.id !== removeCard?.index)
          );
        } else {
          setSelectedAssets((prev) =>
            item?.id === addCard?.index ? [...prev, asset] : prev
          );
        }
        if (isAnimate === "stop") {
          setAnimationTriger(false);
        }
      }, delay);
    },
    [asset, setSelectedAssets]
  );

  useEffect(() => {
    if (assetsInView && animationTrigeer) {
      [1, 3, 2, 2, 3]?.forEach((number, index) => {
        if (index < 3) {
          createCardAnimation(
            asset,
            { isAdd: true, index: number },
            undefined,
            (index + 1) * 1000
          );
        } else if (index > 2 && index <= 4) {
          createCardAnimation(
            asset,
            undefined,
            { isRemove: true, index: number },
            (index + 1) * 1000,
            "stop"
          );
        }
      });
    }
  }, [assetsInView, animationTrigeer, asset, createCardAnimation]);

  return (
    <>
      <Flex
        onClick={() => getSelectedAssets(id)}
        shadow={"0px 4px 36px 2px rgba(0, 0, 0, 0.20)"}
        w={{ base: "full", md: "275px", lg: "300px" }}
        rounded={"xl"}
        justify={"space-between"}
        align={"center"}
        paddingEnd={5}
        border={"2px"}
        cursor={"pointer"}
        overflow={"hidden"}
        borderColor={checkedAsset() ? "primary" : "transparent"}
        transitionDuration={"500ms"}
        outline={"none"}
        style={{ WebkitTapHighlightColor: "transparent" }}
      >
        <HStack justify={"baseline"}>
          <Box w={"65px"}>
            <Image src={image} w={"full"} objectFit={"cover"} />
          </Box>
          <Box>
            <Heading fontSize={"lg"}>{title}</Heading>
            <Text fontSize={"sm"} color={"gray.500"} fontWeight={"semibold"}>
              up to ₹{formatNumberWithCommas(loanValue)} @ {interest}%
            </Text>
          </Box>
        </HStack>
        <IconButton
          size={"sm"}
          bg={"primary"}
          color="white"
          aria-label="Plus"
          transitionDuration={"1s"}
          icon={checkedAsset() ? <BsCheckLg /> : <HiPlus />}
          onClick={() => getSelectedAssets(id)}
          isRound={true}
          _hover={{ bg: "primary", color: "white" }}
          _focus={{
            outline: "none",
          }}
          outline={"none"}
        />
      </Flex>
    </>
  );
}
