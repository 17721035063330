import {
  Collapse,
  Flex,
  Icon,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { HiChevronDown } from "react-icons/hi";
import { Link, NavLink } from "react-router-dom";

export const MobileNavbar = ({ navItem, onClose }) => {
  const { isOpen, onToggle } = useDisclosure();
  return (
    <>
      <Stack
        p={2}
        mx={5}
        display={{ md: "none" }}
        bg={"#7572FF"}
        mt={4}
        rounded={"5"}
      >
        {navItem.map((item, i) => (
          <Stack key={i} spacing={4} onClick={item.children && onToggle}>
            <Flex
              py={1}
              pl={2}
              as={NavLink}
              to={item.href}
              align={"center"}
              transition={"all .25s ease-in-out"}
              borderRadius={5}
              _hover={{
                bg: "#5956E9",
              }}
              role={"group"}
              onClick={onClose}
              style={({ isActive, isPending }) => {
                return {
                  background: isActive ? "#5956E9" : "",
                };
              }}
            >
              <Text fontWeight={600} textColor={"white"}>
                {item.label}
              </Text>
              {item.children && (
                <Icon
                  as={HiChevronDown}
                  transition={"all .25s ease-in-out"}
                  transform={isOpen ? "rotate(180deg)" : ""}
                  w={6}
                  h={6}
                  ml={1}
                  _groupHover={{ color: "primary.700" }}
                />
              )}
            </Flex>

            <Collapse in={isOpen} animateOpacity style={{ marginTop: "0" }}>
              <Stack mt={2} pl={4} align={"start"}>
                {item.children &&
                  item.children.map((child, i) => (
                    <Text
                      as={Link}
                      key={i}
                      href={child.href}
                      fontWeight={"semibold"}
                      _hover={{ color: "primary.700" }}
                      transition={"all .25s ease-in-out"}
                    >
                      {child.label}
                    </Text>
                  ))}
              </Stack>
            </Collapse>
          </Stack>
        ))}
        {/* <Stack
          flex={{ base: 1, md: 0 }}
          justify={"flex-start"}
          direction={"column"}
          spacing={4}
          alignItems={"center"}
          pt={10}
        ></Stack> */}
      </Stack>
    </>
  );
};
