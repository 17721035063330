import { Box, Text } from "@chakra-ui/react";
import { useEffect, useRef } from "react";
import "./VerticalTextSlider.css";

function VerticalTextSlider({ sliderText }) {
  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      const texts = ref.current.children;
      const textsLength = texts.length;
      let index = 0;
      function animateText() {
        const textInTimer = 4000;
        const textOutTimer = 3500;
        for (let i = 0; i < textsLength; i++) {
          texts[i].classList.remove("text-in", "text-out");
        }
        texts[index].classList.add("text-in");

        setTimeout(() => {
          texts[index].classList.add("text-out");
        }, textOutTimer);

        setTimeout(() => {
          if (index === textsLength - 1) {
            index = 0;
          } else {
            index++;
          }
          animateText();
        }, textInTimer);
      }
      animateText();
    }
  }, [ref]);
  return (
    <>
      <Box className="row">
        <Box className="home-text">
          <Box ref={ref} className="animate-text">
            {sliderText?.map((item, i) => (
              <Text
                key={i}
                display={"inline-block"}
                bg={"primary"}
                px={1}
                borderRadius={"lg"}
                color="white"
              >
                {item}
              </Text>
            ))}
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default VerticalTextSlider;
